import { Spin } from 'antd';

const LoadingSpinner = () => {
  return (
    <div className="absolute-center">
      {/* <CSpinner color="primary" style={{ width: '4rem', height: '4rem' }} /> */}
      <Spin spinning={true} />
    </div>
  );
};

export default LoadingSpinner;
