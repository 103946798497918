// @ts-ignore
import ScannerDetector from 'js-scanner-detection';

new ScannerDetector({
  onComplete: (code: any) => {
    const keyword = code.split(' ');
    console.log(keyword[keyword.length - 1], '===keyword');
    if (code) {
      const event = new CustomEvent('barcode', { detail: { code: keyword[keyword.length - 1] } });
      window.dispatchEvent(event);
    }
  },
});
