const configs = {
  APP_ENV: process.env.REACT_APP_ENV,
  API_DOMAIN: process.env.REACT_APP_API_DOMAIN,
  WEB_DOMAIN: process.env.REACT_APP_WEB_DOMAIN,
  // API_DOMAIN: 'http://localhost:3000',
  AWS_DOMAIN: process.env.REACT_APP_AWS_DOMAIN,
  TINYMCE_API_KEY: process.env.REACT_APP_TINYMCE_API_KEY,
  QR_SCAN_DELAY: process.env.QR_SCAN_DELAY
};

export default configs;
