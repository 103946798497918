import style from './style.module.scss';
import { ReloadOutlined } from '@ant-design/icons';

const ButtonReload = () => {
  return (
    <div className={style.buttonReload} onClick={() => window?.location?.reload()}>
      <ReloadOutlined />
    </div>
  );
};

export default ButtonReload;
