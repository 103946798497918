import { Suspense } from 'react';
import { createBrowserHistory } from 'history';
import RootWrapper from 'wrappers/RootWrapper';
import { Router } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import configs from 'config';
import { Helmet } from 'react-helmet';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-quill/dist/quill.snow.css';

export const history = createBrowserHistory();
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 24 * 3600 * 1000, // cache for 1 day
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router history={history}>
        <Suspense fallback={null}>
          <Helmet>
            <title>Clinic Order App</title>
          </Helmet>
          <RootWrapper />
          {configs.APP_ENV !== 'prod' && <ReactQueryDevtools initialIsOpen={false} />}
        </Suspense>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
