import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export default function HelmetHeader() {
  const { t } = useTranslation();
  const getHelmetHeader = (pathname: string) => {
    return t('Clinic Order');
  };
  return (
    <Helmet>
      <title>{getHelmetHeader(window.location.pathname)}</title>
    </Helmet>
  );
}
