import React, { Suspense } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { useQuery } from 'react-query';
import Cookies from 'js-cookie';
import { loadProfile } from 'api/profile';
import HelmetHeader from 'components/HelmetHeader';
import LoadingSpinner from 'components/LoadingSpinner';
import PageHeader from 'components/PageHeader';
// import { getAllResource } from 'api/resources';
// import { getListConfigAdmin } from 'api/configs';
import styles from './styles.module.scss';
import { getAllResource } from 'api/resources';
import { getPermissionsOfMe } from 'api/permission';
import useSelectedBranch from 'hooks/useSelectedBranch';

//Main Route
const Dashboard = React.lazy(() => import('pages/Dashboard'));

//Languages
const Languages = React.lazy(() => import('pages/System/Languages'));

//Logactions
const Logactions = React.lazy(() => import('pages/System/LogAction'));

//Configs
const Roles = React.lazy(() => import('pages/System/Roles'));
const Configs = React.lazy(() => import('pages/Configs'));
const ConfigSystem = React.lazy(() => import('pages/ConfigSystem'));
const AccountProfile = React.lazy(() => import('pages/Profile/ChangeProfile'));
const ChangePassword = React.lazy(() => import('pages/Profile/ChangePassword'));
const Staff = React.lazy(() => import('pages/System/Staffs'));
const StaffCreate = React.lazy(() => import('pages/System/Staffs/Create'));
const StaffEdit = React.lazy(() => import('pages/System/Staffs/Edit'));
const StaffDetail = React.lazy(() => import('pages/System/Staffs/Detail'));
const Patient = React.lazy(() => import('pages/System/Patients'));
const PatientCreate = React.lazy(() => import('pages/System/Patients/Create'));
const PatientDetail = React.lazy(() => import('pages/System/Patients/Detail'));
const PatientEdit = React.lazy(() => import('pages/System/Patients/Edit'));
const MedicalEdit = React.lazy(() => import('pages/System/Patients/EditMedical'));

const Calendar = React.lazy(() => import('pages/Calendar'));
// const CalendarByRoom = React.lazy(() => import('pages/Calendar/ModalCalendarByRoom'));
const Rooms = React.lazy(() => import('pages/System/Rooms'));
const RoomsCreate = React.lazy(() => import('pages/System/Rooms/Create'));
const RoomEdit = React.lazy(() => import('pages/System/Rooms/Edit'));
const Holiday = React.lazy(() => import('pages/System/Holiday'));
const Notification = React.lazy(() => import('pages/System/Notification'));
const FeedBack = React.lazy(() => import('pages/System/FeedBack'));

//Booking
const BookingDetail = React.lazy(() => import('pages/Booking/index'));

//Master Data
const MasterData = React.lazy(() => import('pages/System/MasterData'));

//Terms
const Terms = React.lazy(() => import('pages/System/TermsV2'));
const TermsCreate = React.lazy(() => import('pages/System/TermsV2/Create'));
const TermsEdit = React.lazy(() => import('pages/System/TermsV2/Edit'));

const Appointment = React.lazy(() => import('pages/Appointment'));

//Course
const CourseDetail = React.lazy(() => import('pages/Course'));

const WithdrawRequest = React.lazy(() => import('pages/System/WithdrawRequest'));
const RevenueStatistic = React.lazy(() => import('pages/System/RevenueStatistic'));

export default function AuthWrapper() {
  const { setSelectedBranch } = useSelectedBranch();
  const isAuthenticated = !!Cookies.get('token');
  const { data: profile } = useQuery('profile', loadProfile, { enabled: isAuthenticated });
  const { data: resources } = useQuery('resources', getAllResource, {
    onSuccess: (data) => {
      const branches = data?.data?.clinicBranchs;
      if (branches?.length > 0) {
        let selectedBranch = window.localStorage.getItem('selectedBranch') || '';
        if (!branches.some((branch: any) => branch.id === Number(selectedBranch))) {
          selectedBranch = `${data?.data?.clinicBranchs[0]?.id}`;
        }
        setSelectedBranch(selectedBranch);
      }
    },
    cacheTime: Infinity,
    enabled: isAuthenticated,
  });
  // const { data: listConfigs } = useQuery('listConfigs', () => getListConfigAdmin({}), {
  //   cacheTime: Infinity,
  //   enabled: isAuthenticated,
  // });

  useQuery('getPermissionsOfMe', () => getPermissionsOfMe(), {
    cacheTime: Infinity,
    enabled: isAuthenticated,
  });

  if (!isAuthenticated) {
    window.localStorage.setItem('prevHref', window.location.href);
    return <Redirect to="/login" />;
  }
  if (!profile || !resources) return <LoadingSpinner />;

  return (
    <div className={styles.pageWrapper}>
      <HelmetHeader />
      <PageHeader />
      <div className={`${styles.pageContent} pageContent`}>
        <Suspense fallback={null}>
          <Switch>
            <Route path="/dashboard" exact={true} component={Dashboard} />
            <Route path="/roles" exact={true} component={Roles} />
            <Route path="/languages" exact={true} component={Languages} />
            <Route path="/configs" exact={true} component={Configs} />
            <Route path="/config-system" exact={true} component={ConfigSystem} />
            <Route path="/account/profile" exact={true} component={AccountProfile} />
            <Route path="/account/change-password" exact={true} component={ChangePassword} />
            <Route path="/staffs" exact={true} component={Staff} />
            <Route path="/staffs/create" exact={true} component={StaffCreate} />
            <Route path="/staffs/edit/:staffId" exact={true} component={StaffEdit} />
            <Route path="/staffs/detail/:staffId" exact={true} component={StaffDetail} />
            <Route path="/calendar" component={Calendar} />
            {/* <Route path="/calendar-room" component={CalendarByRoom} /> */}
            <Route path="/rooms" exact={true} component={Rooms} />
            <Route path="/rooms/create" exact={true} component={RoomsCreate} />
            <Route path="/rooms/edit/:roomId" exact={true} component={RoomEdit} />
            <Route path="/patients" exact={true} component={Patient} />
            <Route path="/patients/create" exact={true} component={PatientCreate} />
            <Route path="/patients/detail/:memberId" exact={true} component={PatientDetail} />
            <Route path="/patients/edit/:memberId" exact={true} component={PatientEdit} />
            <Route path="/patients/edit-medical/:memberId" exact={true} component={MedicalEdit} />
            <Route path="/booking/:memberId/detail/:bookingId" exact={true} component={BookingDetail} />
            <Route path="/holiday" exact={true} component={Holiday} />
            <Route path="/notification" exact={true} component={Notification} />
            <Route path="/feedback" exact={true} component={FeedBack} />
            <Route path="/languages" exact={true} component={Languages} />
            <Route path="/logaction" exact={true} component={Logactions} />
            <Route path="/masterdata" exact={true} component={MasterData} />
            <Route path="/terms" exact={true} component={Terms} />
            <Route path="/terms/create" exact={true} component={TermsCreate} />
            <Route path="/terms/edit/:termId" exact={true} component={TermsEdit} />
            <Route path="/booking-history" exact={true} component={Appointment} />
            <Route path="/course/detail/:courseId" exact={true} component={CourseDetail} />
            <Route path="/withdraw-request" component={WithdrawRequest} />
            <Route path="/revenue-statistic" component={RevenueStatistic} />
            <Redirect from="/" to="/dashboard" />
          </Switch>
        </Suspense>
      </div>
    </div>
  );
}
