import Cookies from 'js-cookie';
import configs from 'config';
import { io } from 'socket.io-client';
import { loadProfile } from 'api/profile';

const socket = io(`${configs.API_DOMAIN!}`, { transports: ['websocket', 'polling'] });

socket.on('connect', () => {
  authenticateSocket();
  console.log('connect', socket.id);
});

export const authenticateSocket = async () => {
  if (Cookies.get('token')) {
    await loadProfile(); // refresh token before authen socket
    socket.emit('authenticate', { token: Cookies.get('token'), role: 'Admin' });
  }
};

socket.on('unauthenticated', () => {
  console.log('socket unauthenticated', socket.id);
  //loadProfile();
});

socket.on('authenticated', () => {
  console.log('socket authenticated', socket.id);
});

socket.on('disconnect', (reason) => {
  console.log('disconnect', socket.id, reason);
});
export default socket;
