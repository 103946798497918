import configs from 'config';
import CustomNotification from 'components/CustomNotification';
import { Moment } from 'moment';
import i18next, { TFunction } from 'i18next';
import {
  AgeType,
  AspirationType,
  BEGIN_WORK_TIME,
  BillBookingStatus,
  BookingStatus,
  BookingType,
  CardPaymentMethod,
  CardPaymentType,
  DAYOFWEEK,
  END_WORK_TIME,
  GenderType,
  MedicalExaminationType,
  MedicalRecordUsageType,
  PositionType,
  ReceiptDetailType,
  RoomFunctionType,
  RoomStatus,
} from 'common/enum';
import moment from 'moment-timezone';
import { Obj } from 'interfaces';

export const customLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export const emailRegex = /^(\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+)$/;
export const phoneRegex = /^([0-9\\+\\s-]{8,13})$/;

export const handleErrorMessage = (error: any) => {
  CustomNotification({
    type: 'error',
    message: i18next.t('common.error'),
    description: getErrorMessage(error),
  });
  if (configs.APP_ENV !== 'prod') {
    // tslint:disable-next-line: no-console
    console.log(error);
  }
};

export const handleSuccessMessage = (memberCode: string) => {
  CustomNotification({
    type: 'success',
    message: i18next.t('common.success'),
    description: 'お客様{0}のチェックインができました。'.replace('{0}', memberCode),
  });
};

export const getErrorMessage = (error: any) => {
  return error?.response?.data?.errorMessage || i18next.t('notification.somethingWentWrong');
};

export const assignDomain = (url: string) => {
  if (url?.startsWith('http')) return url;
  return `${configs.AWS_DOMAIN}/${url}`;
};

export const getBase64 = (img: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result as string));
    reader.readAsDataURL(img);
  });

export const getDataFile = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result as string));
    reader.readAsText(file);
  });

export const convertDate = (date: string) => {
  return date ? moment(date).format('YYYY-MM-DD') : '';
};

export const convertDateWithTime = (date: string) => {
  return date ? moment(date).format('YYYY-MM-DD HH:mm') : '';
};

export const convertDayOfWeek = (day: number) => {
  if (day === DAYOFWEEK.MONDAY) return i18next.t('common.monday');
  if (day === DAYOFWEEK.TUESDAY) return i18next.t('common.tuesday');
  if (day === DAYOFWEEK.WEDNESDAY) return i18next.t('common.wednesday');
  if (day === DAYOFWEEK.THURSDAY) return i18next.t('common.thursday');
  if (day === DAYOFWEEK.FRIDAY) return i18next.t('common.friday');
  if (day === DAYOFWEEK.SATURDAY) return i18next.t('common.saturday');
  if (day === DAYOFWEEK.SUNDAY) return i18next.t('common.sunday');
};

export const convertColorEvent = (status: number, bgColor?: string, isFirstCheckIn?: number, hasCheckin?: boolean) => {
  if (status === BookingStatus.BLOCK && bgColor) return { color: 'black' };
  if (status === BookingStatus.BOOKING) return { color: 'black', bgColor: 'linear-gradient(#FFFFFF, #79EA7D)' };
  if (status === BookingStatus.ARRIVED) {
    if (isFirstCheckIn && hasCheckin) {
      return { color: 'black', bgColor: 'linear-gradient(#FFFFFF, #e3a909)' };
    }
    return { color: 'black', bgColor: 'linear-gradient(#FFFFFF, #FFF978)' };
  }
  if (status === BookingStatus.CONSULTING) return { color: 'black', bgColor: 'linear-gradient(#FFFFFF, #FF883C)' };
  if (status === BookingStatus.EXAMINING) return { color: 'black', bgColor: 'linear-gradient(#FFFFFF, #FFFFFE)' };
  if (status === BookingStatus.PAYING) return { color: 'black', bgColor: 'linear-gradient(#FFFFFF, #74F3FF)' };
  if (status === BookingStatus.NOT_WASHING_FACE)
    return { color: 'black', bgColor: 'linear-gradient(#FFFFFF, #000FFF)' };
  if (status === BookingStatus.WAITING_FOR_TREATMENT)
    return { color: 'black', bgColor: 'linear-gradient(#FFFFFF, #000000)' };
  if (status === BookingStatus.BEING_TREATED) return { color: 'black', bgColor: 'linear-gradient(#FFFFFF, #FF8B80)' };
  if (status === BookingStatus.TREATED) return { color: 'black', bgColor: 'linear-gradient(#FFFFFF, #8286FF)' };
  if (status === BookingStatus.AFTER_PAYMENT) return { color: 'black', bgColor: 'linear-gradient(#FFFFFF, #FD0000)' };
  if (status === BookingStatus.DONE) return { color: 'black', bgColor: 'linear-gradient(#FFFFFF, #FD03FD)' };
  if (status === BookingStatus.PAID) return { color: 'black', bgColor: 'linear-gradient(#FFFFFF, #FFFFFF)' };
  if (status === BookingStatus.CANCELLED) return { color: 'black', bgColor: 'linear-gradient(#FFFFFF, #C1C1C1)' };
  if (status === BookingStatus.CANCELLED_2) return { color: 'black', bgColor: 'linear-gradient(#FFFFFF, #C2C2C2)' };
  if (status === BookingStatus.BLOCK) return { color: 'black', bgColor: 'linear-gradient(#FFFFFF, #ced2d8)' };
  if (status === BookingStatus.ANESTHESIA) return { color: 'black', bgColor: 'linear-gradient(#FFFFFF, #DBBFBD)' };
};

// Kiểm tra booking có đặt trong khung giờ 11-20h ngày hôm đó không
export const checkBoookingInWorkTime = (event: any, viewingDate: Moment, status: number) => {
  return (
    status === BookingStatus.BOOKING &&
    event?.karuteNumber &&
    !event?.isTimeOff &&
    moment().isSameOrBefore(moment(event?.end)) &&
    moment(event?.updatedTimeBooking).isSame(moment(), 'day') &&
    moment().isSame(viewingDate, 'day') &&
    (moment(event?.updatedTimeBooking).hour() >= BEGIN_WORK_TIME ||
      moment(event?.updatedTimeBooking).hour() <= END_WORK_TIME)
  );
};

export const checkBoooking1HoursAgo = (event: any, viewingDate: Moment, status: number) => {
  const bookingInDay =
    status === BookingStatus.BOOKING &&
    event?.karuteNumber &&
    !event?.isTimeOff &&
    moment().isSameOrBefore(moment(event?.end)) &&
    moment(event?.updatedTimeBooking).isSame(moment(), 'day') &&
    moment(event?.start).diff(moment(event?.updatedTimeBooking), 'minutes') <= 60 &&
    moment(event?.start).diff(moment(event?.updatedTimeBooking), 'minutes') > 0;

  return bookingInDay;
};
//kiểm tra booking đã thanh toán hoặc đƯợc thêm từ step
export const checkBoookingIsPrepaidOrInAddStep = (event: any) => {
  return (
    event?.status === BookingStatus.BOOKING &&
    event?.karuteNumber &&
    !event?.isTimeOff &&
    moment().isSameOrBefore(moment(event?.end)) &&
    (event?.isPrepaid || !!event?.isFromAddStep)
  );
};

export const convertEventResize = (type: number) => {
  if (type === 1) return 'resize';
  return 'resize-element';
};

export const getWeekOfMonth = (date: Moment) => {
  const weekOfMonth = date.week() - moment(date).startOf('month').week() + 1;
  return `${date.format('M')}月～${weekOfMonth}週目`;
};

export const convertPosition = (position: PositionType) => {
  return position === PositionType.DOCTOR ? i18next.t('option.doctor') : i18next.t('option.nurse');
};

export const convertGender = (gender: GenderType) => {
  return gender === GenderType.MALE ? i18next.t('option.male') : i18next.t('option.female');
};

export const getRoomName = (roomData: Array<any>, roomId: Number) => {
  return roomData?.find((room) => room.id === roomId)?.title;
};

export const convertAspirationType = (aspirationType: AspirationType) => {
  return aspirationType === AspirationType.NURSE ? `看護師` : `医師施術`;
};

export const convertMedicalExaminationType = (medicalExaminationType: MedicalExaminationType) => {
  return medicalExaminationType === MedicalExaminationType.ONLINE
    ? `オンラインカウンセリング (脱毛のみ)`
    : `来院カウンセリング`;
};

export const convertRoom = (rooms: Array<any>, roomId: number) => {
  return rooms?.find((item: any) => item.id === roomId);
};

export const convertTime = (time: string) => {
  return time ? `${moment(time).format('HH:mm')}` : `---`;
};

export const convertRangeTime = (startTime: string, endTime: string) => {
  return startTime && endTime ? `${moment(startTime).format('HH:mm')} ~ ${moment(endTime).format('HH:mm')}` : `---`;
};

export const convertDateTime = (date: any) => {
  return date
    ? `${moment(date).format(`M`)}月${moment(date).format(`DD`)}日 (${convertDayOfWeek(moment(date).day())})`
    : ``;
};

export const getRangeTime = (startTime: string, endTime: string) => {
  return startTime && endTime
    ? `${moment(startTime).format('YYYY/MM/DD')} - ${moment(startTime).format('HH:mm')} ~ ${moment(endTime).format(
        'HH:mm'
      )}`
    : `---`;
};

export const convertAgeType = (ageType: AgeType) => {
  return ageType === AgeType.LTE_19YO ? `19歳以下` : `20歳以上`;
};

export const convertBookingType = (bookingType: BookingType) => {
  return bookingType === BookingType.CONSULT ? `来院診断` : `コース施術`;
};

export const checkIsBookingPlan = (type: BookingType, usageType: MedicalRecordUsageType) => {
  return type === BookingType.EXAMINATION && usageType === MedicalRecordUsageType.PLAN;
};

export const checkIsBookingFree = (type: BookingType, medicalRecord: { usageType: MedicalRecordUsageType }) => {
  return (
    type === BookingType.EXAMINATION && (medicalRecord?.usageType === MedicalRecordUsageType.FREE || !medicalRecord)
  );
};

export const checkIsBookingConsult = (type: BookingType) => {
  return type === BookingType.CONSULT;
};
export const checkIsBookingTrialExamination = (type: BookingType) => {
  return type === BookingType.TRIAL_EXAMINATION;
};

export const roomType = (type: number) => {
  const arr: any = { 1: 'SG', 2: 'SL', 3: 'MG', 4: 'D', 5: 'N', 6: 'CSO', 7: 'CSN' };
  return arr[type];
};

export const getBookingStep = (stepNumber: number | undefined, totalStep: number | undefined) => {
  return stepNumber && totalStep ? `${stepNumber}/${totalStep}*` : '';
};

export const getBookingType = (type: BookingType) => {
  return type === BookingType?.CONSULT ? 'common.CONSULT' : 'common.TRIAL_EXAMINATION';
};

export const disabledDate = (current: any) => {
  return current && current <= moment().subtract(1, 'days');
};

export const disableFutureDate = (current: any) => {
  return current && current > moment();
};

export const convertAge = (birthDay: Date | string) => {
  return birthDay ? moment().diff(birthDay, 'years') : '';
};

export const convertRoomFunctionType = (roomFunctionType: RoomFunctionType) => {
  if (roomFunctionType === RoomFunctionType.EXAM_NORMAL) return i18next.t('option.examNormal');
  if (roomFunctionType === RoomFunctionType.CONSULT_OFF) return i18next.t('option.consultOff');
  if (roomFunctionType === RoomFunctionType.CONSULT_ONL) return i18next.t('option.consultOn');
  if (roomFunctionType === RoomFunctionType.FREE_FOR_DOCTOR) return i18next.t('option.freeForDoctor');
  if (roomFunctionType === RoomFunctionType.FREE_FOR_NURSE) return i18next.t('option.freeForNurse');
};

export const convertBookingStatus = (status: BookingStatus) => {
  return status === BookingStatus.CANCELLED_2
    ? i18next.t('option.cancel_2')
    : status === BookingStatus.CANCELLED
    ? i18next.t('option.cancel')
    : i18next.t('option.done');
};

export const convertStatusRoom = (status: RoomStatus) => {
  return status === RoomStatus.INACTIVE ? i18next.t('common.inActiveRoom') : i18next.t('common.activeRoom');
};

export const convertOptionNumber = (optionNumber: number) => {
  if (optionNumber === 1) return i18next.t('option.hairLoss');
  if (optionNumber === 2) return i18next.t('option.botox');
  if (optionNumber === 3) return 'ヒアルロン酸';
  if (optionNumber === 4) return 'アグネス（ニキビ治療）';
  if (optionNumber === 5) return '針脱毛、ピーリング';
  if (optionNumber === 6) return 'ダーマペン';
  if (optionNumber === 7) return 'HIFU';
  if (optionNumber === 8) return '針脱毛';
  if (optionNumber === 9) return 'イオン導入';
  if (optionNumber === 10) return 'フォトフェイシャル';
};
export const convertConsultOptionNumbers = (
  options: Array<{ optionNumber: number }> | undefined,
  consultOptions: Array<{ value: number; name: string }>
) => {
  const optionNumbers = options?.map((x) => x.optionNumber);
  return optionNumbers
    ?.map((optionNumber) => consultOptions?.find((x: any) => x.value === optionNumber)?.name)
    .join('、');
};

export const formatNumber = (value: number) => {
  if (value === 0 && 1 / value < 0) return '0';
  return `${new Intl.NumberFormat('en-US').format(value)}`;
};
export const unformatNumber = (value: string | number) => {
  return typeof value === 'number' ? value : Number(value?.replace(/[^\d-.]/g, ''));
};

export const roundSign = (value: number) => {
  return Math.sign(value) * Math.round(Math.abs(value));
};

export const getValueConfig = (listConfigs: Obj[], key: string, isEdit?: boolean) => {
  if (listConfigs) {
    const value = listConfigs?.find((el: any) => el.key === key)?.value;
    return isEdit ? Number(value) : value;
  }
  return isEdit ? 0 : '';
};

export const getServiceNameWithOption = (serviceGroup: any, services: any[], options?: any[]) => {
  return (
    serviceGroup?.title +
    '/' +
    (services?.map((service) => service?.title)?.join(' + ') || '') +
    (options?.map((option) => ` + ${option?.name}`)?.join('') || '')
  );
};

export const getReceiptDetailTypeText = (t: TFunction, type?: number) => {
  switch (type) {
    case ReceiptDetailType.Consult:
      return t('sellType.consult');
    case ReceiptDetailType.BuyCourse:
      return t('sellType.buyCourse');
    case ReceiptDetailType.UseCourse:
      return t('sellType.useCourse');
    case ReceiptDetailType.Therapy:
      return t('sellType.therapy');
    case ReceiptDetailType.BuyPoint:
      return t('sellType.buyPoint');
    case ReceiptDetailType.Item:
      return t('sellType.item');
    case ReceiptDetailType.Refund:
      return t('sellType.refund');
    case ReceiptDetailType.Purchase:
      return t('sellType.purchase');
    default:
      return '';
  }
};

export const getCardPaymentTypeText = (type: number) => {
  switch (type) {
    case CardPaymentType.CreditCard:
      return 'クレジットカード';
    case CardPaymentType.ECommerce:
      return '電子マネー';
    case CardPaymentType.Stripe:
      return 'ストライプ';
    case CardPaymentType.Flex:
      return 'フレックス';
    case CardPaymentType.VISA:
      return 'VISA';
    case CardPaymentType.MasterCard:
      return 'MasterCard';
    case CardPaymentType.JCB:
      return 'JCB';
    case CardPaymentType.AmericanExpress:
      return 'American Express';
    case CardPaymentType.Diners:
      return 'Diners';
    default:
      return '';
  }
};

export const getCardPaymentMethodText = (type: number) => {
  switch (type) {
    case CardPaymentMethod.ONE_TIME:
      return '1回払い';
    case CardPaymentMethod.TWICE:
      return '2回払い';
    case CardPaymentMethod.SPLIT:
      return '分割';
    default:
      return '';
  }
};

export const checkBookingBillUnPaid = (bills?: Obj[]) => {
  const billUnPaid = bills?.find((el) => el?.status === BillBookingStatus.UNPAID);
  return !!billUnPaid;
};

export const generateArrayNumber = (number: number, size: number) => {
  if (number === 0) return [];
  const arr = Array.from({ length: number / size + 1 }, (_: any, index: number) => index * size);
  if (arr[arr.length - 1] === number) {
    arr.pop();
  }
  return arr;
};

export const getDetailTitle = (title: any, duration: any) => {
  if ((title || '').includes('分')) {
    return `${title || ''}`;
  }
  return `${title || ''} - ${duration || ''}分`;
};

export const copyTextToClipboard = (value: any) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(String(value || ''));
    CustomNotification({
      type: 'success',
      message: i18next.t('common.success'),
      description: i18next.t('common.copyTextSuccess'),
    });
  }
};

export const alertErrorMessageLocal = (errorText: any) => {
  CustomNotification({
    type: 'error',
    message: i18next.t('common.error'),
    description: errorText || '',
  });
  if (configs.APP_ENV !== 'prod') {
    // tslint:disable-next-line: no-console
    console.log(errorText);
  }
};
