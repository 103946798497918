import React, { lazy, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Route, Switch } from 'react-router-dom';
import AuthWrapper from 'wrappers/AuthWrapper';
import { setResourceI18n } from 'helper/i18n';
import { getListLanguages } from 'api/language';
import LoadingSpinner from 'components/LoadingSpinner';
import isMobile from 'is-mobile';
import ButtonReload from 'components/ButtonReload';

// Pages
const Login = lazy(() => import('pages/Login'));
const SignUp = lazy(() => import('pages/SignUp'));
const Page404 = lazy(() => import('pages/Page404'));
const Page500 = lazy(() => import('pages/Page500'));
const Page25251325 = lazy(() => import('pages/Page25251325'));
const QRScan = lazy(() => import('pages/QRScan'));
const CheckinBK = lazy(() => import('pages/Checkin/index_bk'));
const Checkin = lazy(() => import('pages/Checkin'));
const TermsListLp = React.lazy(() => import('pages/System/TermsV2/ListTermLp'));
const TermsDetail = React.lazy(() => import('pages/System/TermsV2/Detail'));
const TermsConfirm = React.lazy(() => import('pages/System/TermsV2/DetailForm'));

export default function AppWrapper() {
  const [ready, setReady] = useState(false);
  useQuery('listLanguages', getListLanguages);

  useEffect(() => {
    setResourceI18n().then(() => {
      setReady(true);
    });
  }, []);

  if (!ready) return <LoadingSpinner />;

  return (
    <div className="root-wrapper">
      {!!isMobile() && <ButtonReload />}
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/sign-up" component={SignUp} />
        <Route path="/404" component={Page404} />
        <Route path="/500" component={Page500} />
        <Route path="/25251325" component={Page25251325} />
        <Route path="/qr-scan/:clinicBranchId" exact={true} component={QRScan} />
        <Route path="/checkin/:clinicBranchId" exact={true} component={CheckinBK} />
        <Route path="/shinjuku" exact={true} component={Checkin} />
        <Route path="/shibuya" exact={true} component={Checkin} />
        <Route path="/ikebukuro" exact={true} component={Checkin} />
        <Route path="/terms/detail/:termId" exact={true} component={TermsDetail} />
        <Route path="/terms/list-lp" exact={true} component={TermsListLp} />
        <Route path="/terms/confirm/:termId" exact={true} component={TermsConfirm} />
        <Route path="/" component={AuthWrapper} />
      </Switch>
    </div>
  );
}
