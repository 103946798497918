import 'styles/index.scss';
import 'styles/index.css';
import 'antd/dist/antd.css';
import 'helper/i18n';
import 'helper/barcode';
import 'helper/socket';
import 'moment/locale/ja';

import * as serviceWorker from './serviceWorker';

import App from './App';
import { ConfigProvider } from 'antd';
import ReactDOM from 'react-dom';
import jaJP from 'antd/lib/locale/ja_JP';

ReactDOM.render(
  <ConfigProvider locale={jaJP}>
    <App />
  </ConfigProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
