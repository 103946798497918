import { queryClient } from 'App';
import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';

export default function useSelectedBranch() {
  const queryClient = useQueryClient();
  const { data: selectedBranch } = useQuery<string>('selectedBranch', () => {
    return String(queryClient.getQueryData('selectedBranch') || '');
  });
  const setSelectedBranch = useCallback(
    (selectedBranch: string | null) => {
      window.localStorage.setItem('selectedBranch', selectedBranch || '');
      queryClient.setQueryData('selectedBranch', selectedBranch);
    },
    [queryClient]
  );

  return { selectedBranch, setSelectedBranch };
}
export function getSelectedBranch() {
  return String(queryClient.getQueryData('selectedBranch'));
}
