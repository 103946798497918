import { useCallback, useEffect, useMemo, useState } from 'react';
import Cookies from 'js-cookie';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Menu, Dropdown, Select } from 'antd';
import { useQueryClient } from 'react-query';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import downIcon from 'assets/images/downIcon.svg';
import rightIcon from 'assets/images/rightIcon.svg';
import logo from 'assets/images/logo.svg';
import SubMenu from 'antd/lib/menu/SubMenu';
import { SettingOutlined } from '@ant-design/icons';
import configs from 'config';
import { PermissionsCode } from 'common/enum';
import useSelectedBranch from 'hooks/useSelectedBranch';
import classNames from 'classnames';

export default function PageHeader() {
  const { t } = useTranslation();
  const history = useHistory();
  const queryClient = useQueryClient();
  const [selectedKey, setSelectedKey] = useState<any>();
  const location = useLocation();
  const [listPermission, setListPermission] = useState<string[]>([]);
  const { selectedBranch, setSelectedBranch } = useSelectedBranch();

  const { data: profile } = queryClient.getQueryData('profile') as any;
  const { data: resource } = queryClient.getQueryData('resources') as any;

  const handleLogout = () => {
    Cookies.remove('token');
    Cookies.remove('refreshToken');
    history.push('/login');
  };

  const permissionMine = queryClient.getQueryData('getPermissionsOfMe') as any;

  useEffect(() => {
    if (permissionMine) {
      if (permissionMine) {
        const list = [...(permissionMine?.data?.system || []), ...(permissionMine?.data?.general || [])];
        setListPermission(list?.filter((el) => !!el?.hasPermission)?.map((el) => el?.name as string));
      }
    }
  }, [permissionMine]);

  const menus = useMemo(() => {
    const routes = [
      {
        key: '1',
        text: t('pageHeader.dashboard'),
        url: '/dashboard',
      },
      {
        key: '2',
        text: t('pageHeader.calender'),
        url: '/calendar',
      },
      // {
      //   key: '3',
      //   text: t('pageHeader.staffManagement'),
      //   url: '/staffs',
      // },
      {
        key: '4',
        text: t('pageHeader.roomManagement'),
        url: '/rooms',
        disable: !listPermission.includes(PermissionsCode.ROOM_MANAGEMENT),
      },
      {
        key: '5',
        text: t('pageHeader.patientManagement'),
        url: '/patients',
      },
      {
        key: '6',
        text: t('pageHeader.holidayManagement'),
        url: '/holiday',
      },
      {
        key: '7',
        text: t('pageHeader.bookingHistory'),
        url: '/booking-history',
      },
      // {
      //   key: '8',
      //   text: t('pageHeader.masterData'),
      //   url: '/masterdata',
      // },
      {
        key: '9',
        text: t('pageHeader.termManagement'),
        url: '/terms',
      },
      {
        key: '10',
        text: t('pageHeader.notificationManagement'),
        url: '/notification',
      },
      {
        key: '11',
        text: t('pageHeader.feedbackManagement'),
        url: '/feedback',
      },
      {
        key: '12',
        text: t('pageHeader.withdrawManagement'),
        url: '/withdraw-request',
      },
      {
        key: '13',
        text: t('pageHeader.dailyRevenue'),
        url: '/revenue-statistic',
      },
    ];

    return routes;
  }, [t, listPermission]);

  useEffect(() => {
    menus.forEach((route) => {
      if (route.url === '/calendar' && location.pathname.startsWith('/booking')) {
        setSelectedKey(route.key);
      } else if (location.pathname.startsWith(route.url || '###')) {
        setSelectedKey(route.key);
      }
    });
  }, [location.pathname, menus]);

  const menu = (
    <Menu className={styles.dropdown} style={{ minWidth: 200 }}>
      <Menu.Item key="2" onClick={handleLogout} className="d-flex justify-content-between">
        <span>{t('button.logout')}</span>
        <img src={rightIcon} alt="" />
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className={styles.headerWrapper}>
        <div className="d-flex">
          <Link className={styles.logo} to="/dashboard">
            <img src={logo} alt="" />
          </Link>
          <Menu selectedKeys={[selectedKey]} defaultOpenKeys={[]} mode="horizontal" className={styles.navigation}>
            {menus
              ?.filter((el) => !el?.disable)
              .map((route) => {
                return (
                  <Menu.Item key={route.key}>
                    <Link to={route.url} className={styles.textLink}>
                      {route.text}
                    </Link>
                  </Menu.Item>
                );
              })}
            <SubMenu key="SubMenu" icon={<SettingOutlined />} title="" className={styles.menuResponsive}>
              {menus
                ?.filter((el) => !el?.disable)
                .map((route) => {
                  return (
                    <Menu.Item key={route.key}>
                      <Link to={route.url} className={styles.textLink}>
                        {route.text}
                      </Link>
                    </Menu.Item>
                  );
                })}
            </SubMenu>
          </Menu>
        </div>

        <div className={styles.menuWrapper}>
          <Select
            value={selectedBranch}
            size={'small'}
            className={classNames(styles.branchPicker, 'mr-3')}
            onChange={(value) => setSelectedBranch(value)}
          >
            {resource?.clinicBranchs?.map((item: any) => (
              <Select.Option value={item?.id?.toString()}>{item.title}</Select.Option>
            ))}
          </Select>
          <div className={styles.menuItem}>
            <Dropdown overlay={menu} trigger={['click']}>
              <div className={styles.avatar}>
                <div className={styles.name}>{profile?.fullName || `Admin`}</div>
                <img src={downIcon} alt="" className="ml-6" />
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
    </>
  );
}
